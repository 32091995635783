<template>
  <section class="depositions">
    <div v-if="loading">
      <Loading />
    </div>
    <DepositionsHeader :title="deposition.name ? `Deposition: ${deposition.name}` : ''" :datasourceName="deposition.datasourceName" />
    <DepositionProgress :current-status="depositionProgressCurrentStatus || 'Processing'" />
    <div class="tab-buttons is-flex is-justify-content-space-between">
      <div v-for="(tab, index) in allTabs" :key="index" :class="{ active: activeTab === tab }" class="tab-button" @click="setActiveTab(tab)">
        {{ tab }}
      </div>
    </div>

    <div class="main-content is-flex is-justify-content-space-between">
      <div class="active-tab-content" style="width: 100%; height: fit-content">
        <div class="sumary" v-if="activeTab === 'Summary'">
          <h3 class="has-text-weight-bold is-size-4">Summary</h3>

          <div class="all-summary-data">
            <div v-for="(item, key) in summaryData" :key="key" class="summary-data mt-5">
              <p class="has-text-weight-bold custom-font-size-18">{{ item.label }}:</p>
              <div v-if="item.label === 'Deposition ID'" class="copy-wrapper">
                <p class="custom-font-size-18">{{ item.value }}</p>
                <CopyButton :text="item.value" />
              </div>
              <div v-else-if="item.label === 'Status'">
                <p class="custom-font-size-18">
                  {{
                    item.value.parent && item.value.parent !== item.value.label
                      ? `${item.value.parent}:
                                  ${item.value.label}`
                      : item.value.label
                  }}
                </p>
              </div>
              <p v-else class="custom-font-size-18">{{ item.value }}</p>
            </div>
          </div>
        </div>

        <div class="files" v-if="activeTab === 'Files'">
          <DepositionsTable
            :headers="filesHeaders"
            :contents="filesContents"
            :icons="filesIcons"
            :title="activeTab"
            :activeStatus="depositionProgressCurrentStatus"
            :sortList="sortFilesList"
          />
        </div>

        <div class="mapping" v-if="activeTab === 'Mapping'">
          <Mapping
            :deposition-status="depositionProgressCurrentStatus"
            :approved="approved"
            @deposition-rejection="rejection"
            @deposition-approval="approval"
          />
        </div>

        <div class="compounds" v-if="activeTab === 'Compounds'">
          <h3 class="has-text-weight-bold is-size-4">Compounds ({{ compounds.count }})</h3>
          <CompoundsList
            :key="activeTab"
            :raw-compounds="paginatedUndeprecatedCompounds"
            :settings="{ requestedPage: compounds.requestedPage, itemsPerPage: compounds.itemsPerPage }"
            :total-compounds="compounds.count"
            :loading="compounds.loading"
            @get-compounds="getUndeprecatedCompounds"
          />
        </div>

        <div class="deprecated-compounds" v-if="activeTab === 'Deprecated compounds'">
          <h3 class="has-text-weight-bold is-size-4">Deprecated compounds ({{ deprecatedCompounds.count }})</h3>
          <CompoundsList
            :key="activeTab"
            :raw-compounds="paginatedDeprecatedCompounds"
            :settings="{ requestedPage: deprecatedCompounds.requestedPage, itemsPerPage: deprecatedCompounds.itemsPerPage }"
            :total-compounds="deprecatedCompounds.count"
            :loading="deprecatedCompounds.loading"
            @get-compounds="getDeprecatedCompounds"
          />
        </div>

        <div class="history" v-if="activeTab === 'History'">
          <DepositionsTable
            :headers="historyHeaders"
            :contents="historyContents"
            :icons="historyIcons"
            :title="activeTab"
            :activeStatus="depositionProgressCurrentStatus"
            :sortList="sortHistoryList"
          />
        </div>
      </div>
      <!-- Cierre del div de main-content aquí -->
      <div
        v-if="activeTab !== 'History'"
        class="history-bar"
        ref="historyBar"
        style="min-width: 327px; padding: 45px 29px; position: relative; overflow-y: auto; min-height: 300px"
      >
        <div class="is-flex" :style="historyContents[0].status != 'Published' ? 'margin-bottom:80px' : 'margin-bottom:0px'">
          <img
            v-if="historyContents[0].status != 'Published'"
            style="position: absolute; padding-top: 12px"
            src="@/styles/icons/history-icon-awaiting.svg"
            alt="waiting-icon"
          />
          <div class="is-size-4" style="color: #646464; margin-left: 24px" v-if="historyContents[0].status.toLowerCase().includes('uploaded')">
            Awaiting mapping
          </div>
          <div class="is-size-4" style="color: #646464; margin-left: 24px" v-if="historyContents[0].status.toLowerCase().includes('waiting')">
            Awaiting approval
          </div>
          <div class="is-size-4" style="color: #646464; margin-left: 24px" v-if="historyContents[0].status.toLowerCase().includes('register')">
            Awaiting published
          </div>
        </div>
        <div v-for="(item, index) in historyContents" :key="index" style="margin-bottom: 50px">
          <img
            v-if="index !== historyContents.length - 1"
            style="position: absolute; padding-top: 12px"
            src="@/styles/icons/history-icon-done.svg"
            alt="waiting-icon"
          />
          <img
            v-if="index === historyContents.length - 1"
            style="position: absolute; padding-top: 12px"
            src="@/styles/icons/history-icon-circle.svg"
            alt="waiting-icon"
          />
          <div style="margin-left: 24px" class="is-size-4">{{ item.status }}</div>
          <div style="margin-left: 24px">{{ setDate(item.historyDate) }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DepositionsHeader from "@/components/cs-depositions/shared/header.vue";
import DepositionsTable from "@/components/cs-depositions/shared/depositionsTable.vue";
import DepositionProgress from "@/components/cs-depositions/shared/depositionProgress.vue";
import Mapping from "@/components/cs-depositions/shared/mapping.vue";
import CompoundsList from "@/components/cs-depositions/shared/depositionCompoundsList.vue";
import CopyButton from "@/components/shared/copy-button.vue";
import Loading from "@/components/shared/loading.vue";
import { ChemspiderDataService } from "@/api";

export default {
  name: "Depositions",
  components: {
    DepositionsHeader,
    DepositionProgress,
    DepositionsTable,
    Mapping,
    CompoundsList,
    Loading,
    CopyButton,
  },
  data() {
    return {
      loading: false,
      resizeObserver: null,
      deposition: {
        name: "",
        status: "",
        compoundCount: 0,
        type: "",
        submitter: "",
        depositionDate: "",
      },
      compounds: {
        count: 0,
        currentBackendPage: 1,
        requestedPage: 1,
        itemsPerPage: 8,
        loading: false,
        values: [],
      },
      deprecatedCompounds: {
        count: 0,
        currentBackendPage: 1,
        requestedPage: 1,
        itemsPerPage: 8,
        loading: false,
        values: [],
      },
      historyContents: [
        { "TRK#": 11111, historyDate: "2024-04-04T10:51:47.013+00:00", status: "Uploaded", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11112, historyDate: "2024-07-01T10:51:47.013+00:00", status: "Processed", message: "This is a test message", submitter: "Mark A." },
        {
          "TRK#": 11111,
          historyDate: "2024-07-04T10:51:47.013+00:00",
          status: "Published",
          message: "This is a test message",
          submitter: "Mark A.",
          compoundCount: 17,
          failures: 3,
        },
        { "TRK#": 11111, historyDate: "2022-07-04T10:51:47.013+00:00", status: "Uploaded", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11115, historyDate: "2024-02-04T10:51:47.013+00:00", status: "Reuploaded", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11114, historyDate: "2024-07-03T10:51:47.013+00:00", status: "Registered", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11113, historyDate: "2024-07-02T10:51:47.013+00:00", status: "Reprocessed", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11111, historyDate: "2021-07-04T10:51:47.013+00:00", status: "Reprocessed", message: "This is a test message", submitter: "Mark A." },
        { "TRK#": 11118, historyDate: "2023-07-04T10:51:47.013+00:00", status: "Waiting", message: "This is a test message", submitter: "Antonia" },
      ],
      historyHeaders: [{ title: "TRK#" }, { title: "Date" }, { title: "Status" }, { title: "Message" }, { title: "Submitter" }],
      historyIcons: [
        { title: "TRK#", condition: null },
        { title: "Date", condition: true },
        // We can add here more headers that have a sorting icon
      ],
      filesContents: [
        { fileName: "Alinda_Stock_Algo", records: 1001, errors: 1, type: "Original" },
        { fileName: "Alinda_Stock_Algo", records: 1000, errors: 0, type: "Original" },
        { fileName: "Alinda_Dice_Algo", records: 2000, errors: 2, type: "Update" },
        { fileName: "Alinda_Stock_Algo", records: 1250, errors: 1, type: "Update" },
        { fileName: "Alinda_Cositas_Algo", records: 1003, errors: 5, type: "Update" },
        { fileName: "Alinda_Hola_Algo", records: 1006, errors: 9, type: "Original" },
        { fileName: "Alinda_Guapi_Algo", records: 1200, errors: 1, type: "Original" },
      ],
      filesHeaders: [{ title: "Name" }, { title: "Records" }, { title: "Errors" }, { title: "Type" }],
      filesIcons: [
        { title: "Records", condition: null },
        { title: "Errors", condition: true },
        // We can add here more headers that have a sorting icon
      ],
      depositionID: this.$route.path.split("/").pop(),
      allStatus: ["All", "Initially processing", "Awaiting mapping", "Awaiting approval", "Publishing", "Published"],
      status: [
        { label: "New", value: "New", parent: "Processing" },
        { label: "Received", value: "Received", parent: "Processing" },
        { label: "Preprocessing", value: "Preprocess", parent: "Processing" },
        { label: "Structure identification", value: "StructureIdentification", parent: "Processing" },
        { label: "Calculating cheminformatics", value: "Cheminformatics", parent: "Processing" },
        { label: "Approval checkpoint", value: "ApprovalCheckpoint", parent: "Awaiting mapping" },
        { label: "Approval checkpoint", value: "ApprovalCheckpoint", parent: "Awaiting approval" },
        { label: "Approved", value: "Approved", parent: "Publishing" },
        { label: "Rejected", value: "Rejected", parent: "" },
        { label: "Failed", value: "Failed", parent: "" },
        { label: "Publishing", value: "Publishing", parent: "Publishing" },
        { label: "Published", value: "Published", parent: "Published" },
      ],
      initialProcessingStatus: ["Preprocess", "StructureIdentification", "Cheminformatics"],
      allTabs: ["Summary", "Files", "Mapping", "Compounds", "Deprecated compounds", "History"],
      activeTab: "Summary",
      approved: null,
      backendPageSize: 200,
      interval: null,
    };
  },
  computed: {
    summaryData() {
      return [
        { label: "Deposition ID", value: this.deposition.depositionId },
        { label: "Status", value: this.deposition.status },
        { label: "Number of records", value: this.deposition.compoundCount },
        { label: "Type", value: this.deposition.type },
        { label: "Submitter", value: this.deposition.depositor && this.deposition.depositor.name ? this.deposition.depositor.name : "Unknown" },
        {
          label: "Submitted date",
          value: this.setDate(this.deposition.depositionDate),
        },
      ];
    },
    paginatedUndeprecatedCompounds() {
      const startIndex = (this.compounds.requestedPage - 1) * this.compounds.itemsPerPage;
      const endIndex = startIndex + this.compounds.itemsPerPage;
      return this.compounds.values.slice(startIndex, endIndex);
    },
    paginatedDeprecatedCompounds() {
      const startIndex = (this.deprecatedCompounds.requestedPage - 1) * this.deprecatedCompounds.itemsPerPage;
      const endIndex = startIndex + this.deprecatedCompounds.itemsPerPage;
      return this.deprecatedCompounds.values.slice(startIndex, endIndex);
    },
    depositionProgressCurrentStatus() {
      return this.deposition.status.parent || this.deposition.status.label;
    },
  },
  methods: {
    async initialiseDepositionId() {
      const [deposition, recordsCount] = await Promise.all([
        ChemspiderDataService.getDepositionId(this.depositionID),
        ChemspiderDataService.getRecordsCountStatistics(this.depositionID),
      ]);

      this.deposition = deposition.Data;
      this.approved = deposition.Data.approved;
      this.updateCompoundStatistics(recordsCount);
      this.updateDepositionStatus();
      this.updateHistory();
      setTimeout(() => {
        this.setHistoryBarHeight();
        this.initResizeObserver();
      }, 1);
    },
    async getUndeprecatedCompounds(pageNo) {
      this.compounds.requestedPage = pageNo;

      const startIndex = (pageNo - 1) * this.compounds.itemsPerPage;
      const endIndex = startIndex + this.compounds.itemsPerPage;

      if (
        this.compounds.values.length === 0 ||
        (this.compounds.values.length > this.compounds.itemsPerPage && endIndex > this.compounds.values.length && endIndex < this.compounds.count)
      ) {
        this.compounds.loading = true;
        const backendPageToFetch = Math.floor(startIndex / this.backendPageSize) + 1;
        try {
          const newCompounds = await ChemspiderDataService.getUndeprecatedRecords(this.depositionID, backendPageToFetch);
          if (newCompounds.ServiceStatus.HasError || !newCompounds.Data || newCompounds.Data.length === 0) throw new Error(`HTTP error`);

          this.compounds.values.push(...newCompounds.Data);
          this.compounds.currentBackendPage = backendPageToFetch;
        } catch (error) {
          console.error("Error fetching compounds from backend:", error);
        }
        this.compounds.loading = false;
      }
    },
    async getDeprecatedCompounds(pageNo) {
      this.deprecatedCompounds.requestedPage = pageNo;

      const startIndex = (pageNo - 1) * this.deprecatedCompounds.itemsPerPage;
      const endIndex = startIndex + this.deprecatedCompounds.itemsPerPage;

      if (
        this.deprecatedCompounds.values.length === 0 ||
        (this.deprecatedCompounds.values.length > this.deprecatedCompounds.itemsPerPage &&
          endIndex > this.deprecatedCompounds.values.length &&
          endIndex < this.deprecatedCompounds.count)
      ) {
        this.deprecatedCompounds.loading = true;
        const backendPageToFetch = Math.floor(startIndex / this.backendPageSize) + 1;

        try {
          const newCompounds = await ChemspiderDataService.getDeprecatedRecords(this.depositionID, backendPageToFetch);
          if (newCompounds.ServiceStatus.HasError || !newCompounds.Data || newCompounds.Data.length === 0) throw new Error(`HTTP error`);

          this.deprecatedCompounds.values.push(...newCompounds.Data);
          this.deprecatedCompounds.currentBackendPage = backendPageToFetch;
        } catch (error) {
          console.error("Error fetching compounds from backend:", error);
        }
        this.deprecatedCompounds.loading = false;
      }
    },
    initResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
      const activeTabContent = document.querySelector(".active-tab-content");
      if (activeTabContent) {
        this.resizeObserver = new ResizeObserver(() => {
          this.setHistoryBarHeight();
        });
        this.resizeObserver.observe(activeTabContent);
      }
    },
    updateDepositionStatus() {
      this.deposition.status =
        this.status.find((s) => {
          if (this.deposition.status === "ApprovalCheckpoint") {
            return this.deposition.mappingProvided ? s.parent === "Awaiting approval" : s.parent === "Awaiting mapping";
          }
          return s.value === this.deposition.status;
        }) ?? {};
    },
    updateHistory() {
      if (this.deposition.statusHistory) {
        this.historyContents = JSON.parse(JSON.stringify(this.deposition.statusHistory));
      } else {
        this.historyContents.sort((a, b) => new Date(b.historyDate) - new Date(a.historyDate));
      }
    },
    updateCompoundStatistics(statistics) {
      if (statistics.ServiceStatus.HasError) return;
      const compoundCount = statistics.Data.existing + statistics.Data.new;
      const deprecatedCompoundCount = statistics.Data.deprecated;

      this.compounds.count = compoundCount;
      this.deprecatedCompounds.count = deprecatedCompoundCount;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      setTimeout(() => {
        if (this.activeTab != "History") this.setHistoryBarHeight();
      }, 1);
    },
    setDate(date) {
      return new Date(date)
        .toLocaleString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })
        .replace(", ", " at ");
    },
    setHistoryBarHeight() {
      if (!document.querySelector(".active-tab-content")) return;
      const activeTabContentHeight = document.querySelector(".active-tab-content").clientHeight;
      const historyBarHeight = document.querySelector(".history-bar");
      if (historyBarHeight) historyBarHeight.style.height = `${activeTabContentHeight}px`;
    },
    sortHistoryList(title, order) {
      if (title == "TRK#" && order == true) this.historyContents.sort((a, b) => b["TRK#"] - a["TRK#"]);
      if (title == "TRK#" && order == false) this.historyContents.sort((a, b) => a["TRK#"] - b["TRK#"]);
      if (title == "Date" && order == true) {
        this.historyContents.sort((a, b) => new Date(b.historyDate) - new Date(a.historyDate));
      }
      if (title == "Date" && order == false) {
        this.historyContents.sort((a, b) => new Date(a.historyDate) - new Date(b.historyDate));
      }
    },
    sortFilesList(title, order) {
      if (title == "Records" && order == true) this.filesContents.sort((a, b) => b.records - a.records);
      if (title == "Records" && order == false) this.filesContents.sort((a, b) => a.records - b.records);
      if (title == "Errors" && order == true) this.filesContents.sort((a, b) => b.errors - a.errors);
      if (title == "Errors" && order == false) this.filesContents.sort((a, b) => a.errors - b.errors);
    },
    uploadRdKitScript() {
      var script = document.createElement("script");
      script.src = "/rdkit/RDKit_minimal.js";
      script.type = "application/javascript";
      script.defer = true;
      script.async = true;
      document.head.appendChild(script);
    },
    rejection() {
      this.approved = false;
      location.reload();
    },
    approval() {
      this.approved = true;
      location.reload();
    },
  },
  async mounted() {
    this.loading = true;
    await this.initialiseDepositionId();
    this.interval = setInterval(this.initialiseDepositionId, 10000);
    this.loading = false;
    this.uploadRdKitScript();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.progress-bar {
  margin: 67px 0px;
}

.progress-bar .status-box {
  text-align: center;
  padding: 13px 24px;
  border: 1px solid black;
  border-right: 1px solid black;
  border-radius: 4px;
  color: black;
}

.tab-buttons > div.active {
  background-color: black;
  color: white;
}

.tab-buttons {
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  margin-bottom: 60px;
}

.tab-buttons .tab-button {
  width: 100%;
  text-align: center;
  padding: 13px 0;
  border-right: 1px solid black;
  cursor: pointer;
  color: black;
}

.tab-buttons .tab-button:last-child {
  border-right: none;
}

.tab-buttons .tab-button.active {
  background-color: black;
  color: white;
}

.main-content {
  gap: 50px;
}

.history-bar {
  background-color: #f5f5f5;
}

.copy-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}
</style>
