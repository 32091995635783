<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <h3 class="has-text-weight-bold is-size-4">Mapping</h3>
    </div>
    <div v-if="loading">
      <Loading />
    </div>
    <textarea
      :value="formattedMappingContent"
      readonly
      class="full-sampled-data"
    ></textarea>
    <div v-for="(item, index) in depositionMappingData" :key="index">
      <h6
        class="filter-dropdown is-size-4 has-text-black"
        @click="toggleItem(index)"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          class="is-size-4 has-text-black"
          style="cursor: pointer; margin-top: 3px"
          :class="item.isOpened ? 'arrow-icon-selected' : 'arrow-icon'"
        />
        {{ item.property }}
      </h6>
      <div
        class="filter-section"
        :class="item.isOpened ? 'show' : 'hide'"
        style="margin-left: 32px"
      >
        <textarea
          :value="item.value"
          readonly
          class="property-sampled-data"
        ></textarea>
        <p>Map to:</p>
        <div class="select-box-wrapper">
          <div
            class="select-box"
            :class="approved !== null ? 'disabled' : ''"
            v-click-outside="closeSelectBox"
          >
            <div class="options-container" :class="{ active: item.isActive }">
              <div
                class="option"
                v-for="option in filteredOptions(item.searchTerm)"
                :key="option.id"
                @click="!option.disabled && selectOption(option, item)"
                :class="{
                  'disabled-option': option.disabled,
                  'ml-3': option.margin !== false,
                  'has-text-weight-bold':
                    option.margin == false &&
                    option.id != 'ExternalId' &&
                    option.id != 'ExternalUrl' &&
                    option.id != 'line',
                  greyed: option.id == 'line',
                }"
              >
                <input
                  type="radio"
                  class="radio"
                  :id="option.id"
                  :name="`category-${index}`"
                  :value="option.id"
                  :disabled="true"
                />
                <label :for="option.id">{{ option.label }}</label>
              </div>
            </div>
            <div
              class="selected is-flex is-justify-content-space-between"
              @click="toggleOptions(index)"
            >
              <p
                :style="{
                  color:
                    (item.selectedOption &&
                      item.selectedOption.label === 'Select') ||
                    (!item.selectedOption && 'Select')
                      ? '#A7A7A7'
                      : 'black',
                }"
              >
                {{ item.selectedOption ? item.selectedOption.label : "Select" }}
              </p>
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                class="is-size-6 has-text-black"
                style="cursor: pointer; margin-top: 3px"
              />
            </div>

            <div
              class="search-box"
              :class="item.isActive ? 'added-opened' : ''"
            >
              <img
                v-if="item.isActive"
                src="@/styles/icons/magnifying-glass.svg"
                alt="magnifying-glass"
                class="search-icon"
              />
              <div class="input-container" v-if="item.isActive">
                <input
                  type="text"
                  placeholder="Search field"
                  v-model="item.searchTerm"
                />
              </div>
            </div>
          </div>
          <div
            class="delete-button-wrapper"
            v-if="item.selectedOption && approved === null"
          >
            <button @click="deleteMapping(item)">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </div>
        </div>
        <div class="response-reaction-wrapper" v-if="item.responseReaction">
          <p class="success" v-if="item.responseReaction.success">
            {{ item.responseReaction.message }}
          </p>
          <p class="error" v-else>
            {{ item.responseReaction.message }}
          </p>
        </div>
      </div>
    </div>
    <div class="buttons-wrapper" v-if="approved === null">
      <div
        class="approval-override-wrapper"
        v-if="!depositionMappingData.some((m) => m.mappingId !== '')"
      >
        <label
          ><input
            v-model="overrideApproval"
            type="checkbox"
            id="aocbox1"
            value="approval_override_checkbox"
          />&nbsp;Approve deposition without any mapped properties.</label
        >
      </div>
      <div class="reject-approve-deposition-wrapper git-hub-style-wrapper">
        <button class="button reject" @click="showModal('reject')">
          Reject deposition
        </button>
        <button
          class="button"
          :disabled="!enabledApproveButton"
          @click="showModal('approve')"
        >
          Approve deposition
        </button>
      </div>
    </div>
    <confirmation-modal
      :visible.sync="confirmationModalVisible"
      :message="`Are you sure you want to ${actionToConfirm} this deposition?`"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    ></confirmation-modal>
    <div class="deposition-status-info" v-if="approved === true">
      Mappings cannot be altered because this deposition has already been
      approved.
    </div>
    <div class="deposition-status-info" v-else-if="approved === false">
      Mappings cannot be altered because this deposition has already been
      rejected.
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ChemspiderDataService } from "@/api";
import Loading from "@/components/shared/loading.vue";
import ConfirmationModal from "@/components/shared/confirmation-modal.vue";

export default {
  name: "Mapping",
  components: {
    FontAwesomeIcon,
    Loading,
    ConfirmationModal,
  },
  props: {
    depositionStatus: {
      type: String,
      required: true,
    },
    approved: {
      type: [Boolean, undefined],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      confirmationModalVisible: false,
      actionToConfirm: "",
      options: [
        { id: "ExternalId", label: "External ID", margin: false },
        { id: "ExternalUrl", label: "External URL", margin: false },
        {
          id: "line",
          label: "━━━━━━━━━━━━━━━━",
          disabled: true,
          margin: false,
        },
        { label: "Identifiers", disabled: true, margin: false },
        { id: "Synonyms", label: "Names and synonyms", margin: true },
        { id: "DatabaseIds", label: "Database IDs", margin: true },
        // { label: 'Experimental properties', disabled: true, margin: false },
        // { id: 'BoilingPoint', label: 'Experimental Boiling Point', margin: true },
        // { id: 'Density', label: 'Experimental Density', margin: true },
        // { id: 'FlashPoint', label: 'Experimental Flash Point', margin: true },
        // { id: 'IonizationPotential', label: 'Experimental Ionization Potential', margin: true },
        // { id: 'LogP', label: 'Experimental LogP', margin: true },
        // { id: 'MeltingPoint', label: 'Experimental Melting Point', margin: true },
        // { id: 'RefractionIndex', label: 'Experimental Refraction Index', margin: true },
        // { id: 'Solubility', label: 'Experimental Solubility', margin: true },
        // { id: 'VaporPressure', label: 'Experimental Vapor Pressure', margin: true },
        // { label: 'Gas chromatography', disabled: true, margin: false },
        // { id: 'RetentionIndexKovats', label: 'Retention Index (Kovats)', margin: true },
        // { id: 'RetentionIndexLee', label: 'Retention Index (Lee)', margin: true },
        // { id: 'RetentionIndexLinear', label: 'Retention Index (Linear)', margin: true },
        // { id: 'RetentionIndexNormalAlkane', label: 'Retention Index (Alkane)', margin: true },
        { label: "Bioactivity", disabled: true, margin: false },
        { id: "Bioactivity", label: "Bioactivity", margin: true },
        { id: "TargetOrgans", label: "Target organs", margin: true },
        { label: "Miscellaneous", disabled: true, margin: false },
        { id: "Appearance", label: "Appearance", margin: true },
        { id: "ChemicalClass", label: "Chemical class", margin: true },
        { id: "Incompatibility", label: "Incompatibility", margin: true },
        { id: "Stability", label: "Stability", margin: true },
        //{ id: 'Toxicity', label: 'Toxicity', margin: true },
        // { label: 'Spectroscopy', disabled: true, margin: false },
        // { id: 'LambdaMax', label: 'LambdaMax', margin: true },
      ],
      mappingClassMap: {
        Datasource: ["ExternalId", "ExternalUrl"],
        PropertyString: [
          "Appearance",
          "Bioactivity",
          "ChemicalClass",
          "Incompatibility",
          "Stability",
          "TargetOrgans",
        ],
        Synonym: ["Synonyms", "DatabaseIds"],
        PropertyDimensional: [], // Not supported for now
      },
      depositionID: this.$route.path.split("/").pop(),
      depositionProperties: [],
      depositionSample: [],
      existingMappings: [],
      depositionMappingData: [],
      sendtoBackData: [],
      depositionStatusAfterSubmit: "",
      overrideApproval: false,
    };
  },
  computed: {
    formattedMappingContent() {
      let result = "";
      for (const item of this.depositionMappingData) {
        const valueString = Array.isArray(item.value)
          ? item.value.join(", ")
          : item.value;
        result += `${item.property}: ${valueString}\n`;
      }
      return result.trim();
    },
    filteredOptions() {
      return (searchTerm) => {
        searchTerm = searchTerm.toLowerCase();
        return this.options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm)
        );
      };
    },
    enabledApproveButton() {
      return (
        this.depositionMappingData.some((m) => m.mappingId !== "") ||
        this.overrideApproval
      );
    },
  },
  methods: {
    async initialiseDepositionMappingData() {
      try {
        const [depositionProperties, depositionSample, existingMappings] =
          await Promise.all([
            ChemspiderDataService.getDepositionIdProperties(this.depositionID),
            ChemspiderDataService.getDepositionIdSample(this.depositionID),
            ChemspiderDataService.getDepositionExistingMappings(
              this.depositionID
            ),
          ]);

        this.depositionProperties = depositionProperties.Data;
        this.depositionSample = depositionSample.Data;
        this.existingMappings = existingMappings.Data;
        this.setDepositionMapping();
      } catch (error) {
        console.error("Error initializing deposition mapping data:", error);
      }
    },
    toggleItem(index) {
      this.depositionMappingData[index].isOpened =
        !this.depositionMappingData[index].isOpened;
    },
    toggleOptions(index) {
      this.depositionMappingData[index].isActive =
        !this.depositionMappingData[index].isActive;
    },
    selectOption(option, item) {
      if (option.id == "ExternalId") {
        this.options = this.options.filter(
          (option) => option.id !== "ExternalId"
        );
      }
      if (option.id == "ExternalUrl") {
        this.options = this.options.filter(
          (option) => option.id !== "ExternalUrl"
        );
      }

      this.mappingBuild(option, item);
    },
    checkAndRestoreOptions() {
      let externalIdSelected = false;
      let externalUrlSelected = false;

      for (let item of this.depositionMappingData) {
        if (item.mappedTo == "ExternalId") externalIdSelected = true;
        if (item.mappedTo == "ExternalUrl") externalUrlSelected = true;
      }
      // Brings back ExternalId to options if it is not selected
      if (!externalIdSelected) {
        if (!this.options.some((option) => option.id === "ExternalId")) {
          this.options.unshift({
            id: "ExternalId",
            label: "External ID",
            margin: false,
          });
        }
      }
      // Brings back ExternalUrl to options if it is not selected
      if (!externalUrlSelected) {
        if (!this.options.some((option) => option.id === "ExternalUrl")) {
          this.options.unshift({
            id: "ExternalUrl",
            label: "External URL",
            margin: false,
          });
        }
      }
    },
    setDepositionMapping() {
      this.checkAndRestoreOptions();
      this.depositionProperties.forEach((item) => {
        let mappedTo = "";
        let selectedOption = "";
        const map = this.existingMappings.mappings.find(
          (m) => m.sourceField === item
        );
        if (map) {
          selectedOption = this.options.find((o) =>
            map.mappingTarget ? o.id === map.mappingTarget : o.id === "Synonyms"
          );
          if (selectedOption) {
            mappedTo = selectedOption.id;
            // Removing unique mappping properties if they already come mapped from server
            if (mappedTo == "ExternalId") {
              this.options = this.options.filter(
                (option) => option.id !== mappedTo
              );
            }
            if (mappedTo == "ExternalUrl") {
              this.options = this.options.filter(
                (option) => option.id !== mappedTo
              );
            }
          }
        }

        this.depositionMappingData.push({
          property: item,
          isOpened: false,
          selectedOption: selectedOption,
          searchTerm: "",
          isActive: false,
          value: "",
          mappedTo: mappedTo,
          mappingId: map ? map.mappingId : "",
        });
      });
      // adds value from Mapping Sample Api
      Object.entries(this.depositionSample).forEach(([key, value]) => {
        for (let entry of this.depositionMappingData) {
          if (key === entry.property) {
            entry.value = Array.isArray(value) ? value.join(", ") : value;
          }
        }
      });
    },
    closeSelectBox(event) {
      const selectBoxes = document.querySelectorAll(".select-box");
      for (let selectBox of selectBoxes) {
        if (selectBox.contains(event.target)) {
          return;
        }
      }
      for (let item of this.depositionMappingData) {
        item.isActive = false;
      }
    },
    async mappingBuild(option, item) {
      const data = {};
      data.mappingClass = this.findKeyByValue(this.mappingClassMap, option.id);
      if (!["Synonyms", "DatabaseIds"].includes(option.id)) {
        data.mappingTarget = option.id;
      }
      if (option.id === "DatabaseIds") {
        data.annotation = "DBID";
      }
      data.sourceField = item.property;

      this.postProcess(await this.sendMapping(data), option, item);
    },
    clearAll() {
      this.depositionMappingData = [];
      this.setDepositionMapping();
    },
    findKeyByValue(map, value) {
      return Object.entries(map).find(([, values]) =>
        values.includes(value)
      )?.[0];
    },
    setRequestReaction(success, item) {
      item.responseReaction = {};
      if (!success) {
        item.responseReaction.success = false;
        item.responseReaction.message = `Property ${item.property} could not be mapped. Check the mapping and try again.`;
        return;
      }

      item.responseReaction.success = true;
      item.responseReaction.message = `Property ${item.property} successfully mapped to ${item.selectedOption.label}.`;
    },
    async sendMapping(data) {
      try {
        return await ChemspiderDataService.sendDepositionMapping(
          this.depositionID,
          data
        );
      } catch (error) {
        console.error(
          "Error creating or updating a deposition mapping:",
          error
        );
      }
    },
    async deleteMapping(data) {
      try {
        if (!data.property) return;
        const response = await ChemspiderDataService.removeDepositionMapping(
          this.depositionID,
          data.property
        );
        data.responseReaction = {};
        if (response.ServiceStatus.HasError) {
          data.responseReaction.success = false;
          data.responseReaction.message = `${data.property} mapping could not be deleted. Try again later.`;
          return;
        }

        data.responseReaction.success = true;
        data.responseReaction.message = `${data.property} mapping has been successfully deleted.`;
        data.mappingId = "";
        data.mappedTo = "";
        data.selectedOption = "";
      } catch (error) {
        console.error("Error deleting a deposition mapping:", error);
      }
    },
    postProcess(response, option, item) {
      item.isActive = false;
      if (response.Data) {
        item.mappingId = response.Data;
        item.selectedOption = option;
      }
      this.setRequestReaction(!response.ServiceStatus.HasError, item);
    },
    async rejectDeposition() {
      const response = await ChemspiderDataService.rejectDeposition(
        this.depositionID,
        ""
      );
      if (!response.ServiceStatus.HasError) {
        this.$emit("deposition-rejection");
      }
    },
    async approveDeposition() {
      const response = await ChemspiderDataService.approveDeposition(
        this.depositionID,
        ""
      );
      if (!response.ServiceStatus.HasError) {
        this.$emit("deposition-approval");
      }
    },
    showModal(action) {
      this.actionToConfirm = action;
      this.confirmationModalVisible = true;
    },
    handleConfirm() {
      if (this.actionToConfirm === "approve") this.approveDeposition();
      if (this.actionToConfirm === "reject") this.rejectDeposition();
    },
    handleCancel() {
      this.actionToConfirm = "";
      this.confirmationModalVisible = false;
    },
  },
  async mounted() {
    this.loading = true;
    await this.initialiseDepositionMappingData();
    this.loading = false;
  },
};
</script>

<style>
button {
  padding: 10px 15px;
  border: 1px solid black;
  cursor: pointer;
}

.filter-dropdown {
  height: 100px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #999999;
  cursor: pointer;
}

.arrow-icon {
  transition: all 0.2s;
}

.arrow-icon-selected {
  transform: scaleY(-1);
  transition: all 0.2s;
}

.show {
  opacity: 1;
  max-height: 1500px;
  overflow: hidden;
  transition: all 0.3s;
}

.hide {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s;
}

.mapping textarea {
  height: 135px;
  width: 100%;
  margin-top: 27px;
  padding: 10px 20px;
  resize: none;
  color: black;
  font-size: 18px;
  background-color: #f5f5f5;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
}

.mapping textarea.property-sampled-data {
  height: 50px;
}

.select-box-wrapper {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}

.delete-button-wrapper button {
  background: white;
  color: black;
  border: 1px solid #a7a7a7;
  border-radius: 4px;
}

.delete-button-wrapper button:hover {
  background: #f5f5f5;
}

.select-box {
  display: flex;
  width: 371px;
  flex-direction: column;
}

.select-box.disabled {
  pointer-events: none;
  opacity: 0.5;
  background: #ccc;
}

.select-box .options-container {
  background: white;
  color: black;
  max-height: 0;
  width: 371px;
  opacity: 0;
  /* transition: all 0.4s; */
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #a7a7a7;
  border-top: none;
  overflow: hidden;
  order: 1;
  position: absolute;
  z-index: 1;
}

.selected {
  /* background: #2f3640; */
  border-radius: 4px;
  /* margin-bottom: 8px; */
  color: #a7a7a7;
  position: relative;
  border: 1px solid #a7a7a7;
  order: 0;
  padding: 6px 9px;
  cursor: pointer;
}

.select-box .options-container.active {
  max-height: 480px;
  opacity: 1;
  overflow-y: scroll;
  margin-top: 125px;
}

.select-box .options-container::-webkit-scrollbar {
  width: 20px;
  border: 1px solid #a7a7a7;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: #a7a7a7;
}

.select-box .option {
  padding: 3px 17px;
  cursor: pointer;
}

.select-box .option:hover {
  background: #f5f5f5;
}

.select-box .disabled-option,
.select-box .disabled-option label {
  cursor: default;
}

.select-box .disabled-option:hover {
  cursor: normal;
  background: white;
}

.greyed {
  color: #d9d9d9;
}

.select-box label {
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
}

.select-box .option .radio {
  display: none;
}

/* Searchbox */

.search-icon {
  position: absolute;
  left: 60px;
  margin-top: 53px;
  transform: translateY(-50%);
  z-index: 3;
}

.search-box .input-container {
  width: 371px;
  height: 100px;
  position: absolute;
  border: 1px solid #a7a7a7;
  background-color: white;
  margin-top: 3px;
}

.search-box input {
  width: 338px;
  height: 48px;
  margin: 24px 15px 12px 15px;
  font-size: 16px;
  position: absolute;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  text-indent: 35px;
  border: none;
  border: 1px solid black;
  border-radius: 4px;
  /* transition: all 0.4s; */
}

.search-box input:focus {
  outline: none;
}

.select-box .options-container.active ~ .search-box input {
  opacity: 1;
  pointer-events: auto;
}

.response-reaction-wrapper {
  font-size: small;
  font-style: italic;
}

.response-reaction-wrapper .success {
  color: #02ad1b;
}

.response-reaction-wrapper .error {
  color: #d1242f;
}

.buttons-wrapper {
  margin-top: 30px;
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
}

.approval-override-wrapper {
  margin-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  font-size: small;
  font-style: italic;
}

.reject-approve-deposition-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 8px;
}

.deposition-status-info {
  margin: 20px 0 0 0;
}
</style>
