import * as axios from "axios";
import { axiosConfig, axiosError, parseResponse, okResult, errorResult } from "@/api";

const WorkbenchChemspider = axios.create({
  baseURL: process.env.VUE_APP_DEPOSITION_SYSTEM_BACKEND,
});

WorkbenchChemspider.interceptors.request.use(
  (config) => {
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';
    return axiosConfig(config);
  },
  axiosError
);

WorkbenchChemspider.interceptors.request.use(axiosConfig, axiosError);

const getAllDepositions = async () => {
  try {
    const response = await WorkbenchChemspider.get("/deposition");
    let data = parseResponse(response);
    const depositions = data;
    return okResult(depositions);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDepositionId = async (depositionID) => {
  try {
    const response = await WorkbenchChemspider.get(`/deposition/${depositionID}`);
    let data = parseResponse(response);
    const deposition = data;
    return okResult(deposition);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getAllDatasources = async () => {
  try {
    const response = await WorkbenchChemspider.get("/datasource");
    let data = parseResponse(response);
    const datasources = data;
    return okResult(datasources);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDatasource = async (datasourceID) => {
  try {
    const response = await WorkbenchChemspider.get(`/datasource/${datasourceID}`);
    let data = parseResponse(response);
    const datasource = data;
    return okResult(datasource);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDepositionIdProperties = async (depositionID) => {
  try {
    const response = await WorkbenchChemspider.get(`/compound_process/properties/${depositionID}`);
    let data = parseResponse(response);
    const deposition = data;
    return okResult(deposition);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDepositionIdSample = async (depositionID) => {
  try {
    const response = await WorkbenchChemspider.get(`/compound_process/sample/${depositionID}`);
    let data = parseResponse(response);
    const deposition = data;
    return okResult(deposition);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDepositionExistingMappings = async (depositionID) => {
  try {
    const response = await WorkbenchChemspider.get(`/mappings/deposition/${depositionID}`);
    let data = parseResponse(response);
    const deposition = data;
    return okResult(deposition);
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const sendDepositionMapping = async (depositionID, mapping) => {
  try {
    const response = await WorkbenchChemspider.put(`/mappings/deposition/${depositionID}/mapping`, mapping);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const removeDepositionMapping = async (depositionID, sourceField) => {
  try {
    const response = await WorkbenchChemspider.delete(`/mappings/deposition/${depositionID}/sourcefield/${sourceField}`);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getRecordsCountStatistics = async (depositionID) => {
  try {
    const response = await WorkbenchChemspider.get(`/records/deposition/${depositionID}/statistics`);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getUndeprecatedRecords = async (depositionID, pageNo) => {
  try {
    const response = await WorkbenchChemspider.get(`/records/deposition/${depositionID}/undeprecated?pageNo=${pageNo}`);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const getDeprecatedRecords = async (depositionID, pageNo) => {
  try {
    const response = await WorkbenchChemspider.get(`/records/deposition/${depositionID}/deprecated?pageNo=${pageNo}`);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const approveDeposition = async (depositionID, approvedBy) => {
  try {
    const response = await WorkbenchChemspider.put(`/deposition/${depositionID}/publication/approve`, JSON.stringify(approvedBy), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const rejectDeposition = async (depositionID, rejectedBy) => {
  try {
    const response = await WorkbenchChemspider.put(`/deposition/${depositionID}/publication/reject`, JSON.stringify(rejectedBy), {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error(error);
    return errorResult(error);
  }
};

const addDatasource = async (datasourceData) => {
  try {
    const response = await WorkbenchChemspider.post(`/datasource/create`, datasourceData);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error("Complete error mesagge:", error);
    if (error.response) {
      console.error(error.response);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error(error.message);
    }
    return errorResult(error);
  }
};

const editDatasource = async (datasourceId, datasourceData) => {
  try {
    const response = await WorkbenchChemspider.put(`/datasource/legacy_id/${datasourceId}/update`, datasourceData);
    if (response.status >= 200 && response.status < 300) {
      return okResult(response.data);
    } else {
      return errorResult(`Error: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error("Complete error mesagge:", error);
    if (error.response) {
      console.error(error.response);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error(error.message);
    }
    return errorResult(error);
  }
};

const addLogo = async (datasourceId, imageFile) => {
  try {
    if (imageFile.type !== "image/png" && imageFile.type !== "image/jpeg") {
      throw new Error("File type must be image/png or image/jpeg.");
    }

    const response = await WorkbenchChemspider.put(`/datasource/${datasourceId}/logo`, imageFile, {
      headers: {
        "Content-Type": imageFile.type,
      },
    });

    return okResult(response.data);
  } catch (error) {
    console.error("Complete error mesagge:", error);
    if (error.response) {
      console.error(error.response);
    } else if (error.request) {
      console.error(error.request);
    } else {
      console.error(error.message);
    }
    return errorResult(error);
  }
};

export const ChemspiderDataService = {
  getAllDepositions,
  getDepositionId,
  getAllDatasources,
  getDatasource,
  getDepositionIdProperties,
  getDepositionIdSample,
  getDepositionExistingMappings,
  sendDepositionMapping,
  removeDepositionMapping,
  getRecordsCountStatistics,
  getUndeprecatedRecords,
  getDeprecatedRecords,
  approveDeposition,
  rejectDeposition,
  addDatasource,
  editDatasource,
  addLogo,
};
