import { render, staticRenderFns } from "./cs-depositions-edit.vue?vue&type=template&id=622b3fdf&scoped=true"
import script from "./cs-depositions-edit.vue?vue&type=script&lang=js"
export * from "./cs-depositions-edit.vue?vue&type=script&lang=js"
import style0 from "./cs-depositions-edit.vue?vue&type=style&index=0&id=622b3fdf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "622b3fdf",
  null
  
)

export default component.exports