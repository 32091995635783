<template>
  <section class="depositions">
    <DepositionsHeader />
    <div v-if="loading">
      <Loading />
    </div>
    <div class="status-buttons is-flex is-justify-content-space-between">
      <div v-for="(status, index) in allStatus" :key="index" :class="{ active: activeStatus === status }"
        @click="setActive(status)">
        {{ status }}
      </div>
    </div>

    <div v-if="depositions.Data && depositions.Data.length > 0">
      <DepositionsTable :headers="headers" :contents="contents" :icons="icons" :activeStatus="activeStatus"
        :sortList="sortList" />

    </div>
  </section>
</template>

<script>
import DepositionsHeader from '@/components/cs-depositions/shared/header.vue'
import DepositionsTable from '@/components/cs-depositions/shared/depositionsTable.vue'
import Loading from '@/components/shared/loading.vue';
import { ChemspiderDataService } from "@/api";

export default {
  name: 'DepositionsList',
  components: {
    DepositionsHeader,
    DepositionsTable,
    Loading
  },
  data() {
    return {
      loading: true,
      headers: [
        { title: 'ID' },
        { title: 'Submitted' },
        { title: 'Status' },
        { title: 'Data source' },
        { title: 'File' },
        { title: 'Records' }
      ],
      icons: [
        { title: 'ID', condition: null },
        { title: 'Submitted', condition: true },
        { title: 'Records', condition: null }
        // We can add here more headers that have a sorting icon
      ],
      depositions: [],
      contents: [],
      allStatus: ['All', 'Processing', 'Awaiting mapping', 'Awaiting approval', 'Publishing', 'Published'],
      status: [
        { label: 'New', value: 'New', parent: 'Processing' },
        { label: 'Received', value: 'Received', parent: 'Processing' },
        { label: 'Preprocessing', value: 'Preprocess', parent: 'Processing' },
        { label: 'Structure identification', value: 'StructureIdentification', parent: 'Processing' },
        { label: 'Calculating cheminformatics', value: 'Cheminformatics', parent: 'Processing' },
        { label: 'Approval checkpoint', value: 'ApprovalCheckpoint', parent: 'Awaiting mapping' },
        { label: 'Approval checkpoint', value: 'ApprovalCheckpoint', parent: 'Awaiting approval' },
        { label: 'Approved', value: 'Approved', parent: 'Publishing' },
        { label: 'Rejected', value: 'Rejected', parent: '' },
        { label: 'Failed', value: 'Failed', parent: '' },
        { label: 'Publishing', value: 'Publishing', parent: 'Publishing' },
        { label: 'Published', value: 'Published', parent: 'Published' },
      ],
      activeStatus: 'All',
      interval: null
    };
  },
  methods: {
    setActive(status) {
      this.activeStatus = status;
      this.$root.$emit('reset-pagination');
    },
    async initialiseDepositions() {
      this.depositions = await ChemspiderDataService.getAllDepositions();
      this.depositions.Data.sort((a, b) => new Date(b.depositionDate) - new Date(a.depositionDate));
      this.setDepositionsTable(this.depositions.Data);
    },
    sortList(title, order) {
      if (title == 'ID' && order == true) {
        this.contents.sort((a, b) => {
          if (a.depositionId > b.depositionId) return -1;
          if (a.depositionId < b.depositionId) return 1;
          return 0;
        });
      }
      if (title == 'ID' && order == false) {
        this.contents.sort((a, b) => {
          if (a.depositionId > b.depositionId) return 1;
          if (a.depositionId < b.depositionId) return -1;
          return 0;
        });
      }
      if (title == 'Submitted' && order == true) this.contents.sort((a, b) => new Date(b.depositionDate) - new Date(a.depositionDate));
      if (title == 'Submitted' && order == false) this.contents.sort((a, b) => new Date(a.depositionDate) - new Date(b.depositionDate));
      if (title == 'Records' && order == true) this.contents.sort((a, b) => b.compoundCount - a.compoundCount);
      if (title == 'Records' && order == false) this.contents.sort((a, b) => a.compoundCount - b.compoundCount);
    },
    setDepositionsTable(depositionsData) {
      this.contents = []; // Initialise contents array

      for (let i = 0; i < depositionsData.length; i++) {
        const data = depositionsData[i];
        let entry = {}; // Initialise empty object

        if (data.depositionId) {
          entry['depositionId'] = data.depositionId;
        } else {
          entry['depositionId'] = ''
        }

        if (data.depositionDate) {
          entry['depositionDate'] = data.depositionDate;
        } else {
          entry['depositionDate'] = ''
        }

        if (data.status) {
          entry['status'] = this.status.find(s => {
            if (data.status === 'ApprovalCheckpoint') {
              return data.mappingProvided ? s.parent === 'Awaiting approval' : s.parent === 'Awaiting mapping';
            }
            return s.value === data.status;
          }) ?? {};
        } else {
          entry['status'] = '';
        }

        if (data.datasourceName) {
          entry['datasourceName'] = data.datasourceName;
        } else {
          entry['datasourceName'] = ''
        }

        if (data.dataPath) {
          entry['dataPath'] = data.dataPath.substring(data.dataPath.lastIndexOf('/') + 1);
        } else {
          entry['dataPath'] = ''
        }

        if (data.compoundCount) {
          entry['compoundCount'] = data.compoundCount;
        } else {
          entry['compoundCount'] = 0;
        }

        if (data.failures) {
          entry['failures'] = data.failures;
        } else {
          entry['failures'] = 0;
        }

        this.contents.push(entry);
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.initialiseDepositions();
    this.interval = setInterval(this.initialiseDepositions, 10000);
    this.contents.sort((a, b) => new Date(b.depositionDate) - new Date(a.depositionDate));
    this.loading = false;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.status-buttons {
  width: 100%;
  border: 1px solid black;
  margin-bottom: 60px
}

.status-buttons>div {
  width: 100%;
  text-align: center;
  padding: 13px 0;
  border-right: 1px solid black;
  cursor: pointer;
}

.status-buttons>div:last-child {
  border-right: none;
}

.status-buttons>div.active {
  background-color: black;
  color: white;
}
</style>
