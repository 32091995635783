<template>
  <transition name="fade">
    <div class="box" :class="{ 'is-disabled': isSaving }" :data-marinlit-id="article.marinLitId">
      <div class="columns is-mobile is-multiline">
        <div class="column is-three-fifths">
          <a v-if="!isDiscarded" :href="`/marinlit/edit/${article.articleId}`" @click.prevent="goToArticle" class="is-block" v-html="article.title"></a>
          <p v-else v-html="article.title"></p>
          <p v-if="article.journalTitle"><ArticleCitation :article="article" /></p>
          <p v-if="article.doi"><ArticleDoiLink :doi="article.doi" /></p>
          <p><b>MarinLit ID:</b> {{ article.marinLitId }}</p>
        </div>
        <div class="column is-two-fifths has-text-right">
          <span class="is-block has-text-weight-bold mb-1">{{ articleStatus }}</span>
          <p class="is-size-7">Last modified {{ lastModifiedDateString }}</p>
        </div>
        <WorkflowButtons
          v-if="!isDiscarded"
          class="column is-full"
          :disabled="isSaving"
          :recordId="article.articleId"
          :recordStatus="article.status"
          :clickedButton="clickedButton"
          :isReadOnly="isArticleReadOnly"
          @preview="previewArticle"
          @update="updateArticleStatus"
        />
      </div>

      <ModalWindow
        class="article-preview"
        :isOpen="isArticlePreviewVisible"
        :heading="`Preview ${article.marinLitId}`"
        :showCancelButton="false"
        @close="toggleArticlePreview"
      >
        <template #body>
          <ArticlePreview :article="article" :compounds="compounds" />
        </template>
        <template #footer>
          <button type="button" class="button" @click="toggleArticlePreview">Close</button>
        </template>
      </ModalWindow>
    </div>
  </transition>
</template>

<script>
import ArticleDataMixin from "@/mixins/marinlit/article-data-mixin";
import { ArticleCitation, ArticleDoiLink, ModalWindow, WorkflowButtons } from "@/components/shared";
import { ArticlePreview } from "@/components/marinlit/shared";
import { WorkflowLabels } from "@/workflow-labels";
import { WorkflowStatus } from "@/workflow-status";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "ArticleResult",
  mixins: [ArticleDataMixin],
  data() {
    return {
      isSaving: false,
      clickedButton: "",
      compounds: [],
      isArticlePreviewVisible: false,
      loadingStatusLabels: {
        approval: "Sending for approval",
        approve: "Approving",
        reject: "Rejecting",
        publish: "Publishing",
        draft: "Returning to draft",
      },
    };
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapFields("MarinLitSearch", {
      StatusMarinLit: "SearchParams.status.value",
    }),
    ...mapGetters("MarinLitSearch", ["getSearchPills", "getSearchQueryString", "getSearchQueryStringWithoutStatus"]),
    lastModifiedDateString() {
      return new Date(this.article.lastModifiedDate).toLocaleDateString("en-GB");
    },
    isDiscarded() {
      return this.article.status === WorkflowStatus.DISCARDED;
    },
    articleStatus() {
      return this.isSaving ? `${this.loadingStatusLabels[this.clickedButton]}...` : WorkflowLabels[this.article.status];
    },
  },
  methods: {
    goToArticle() {
      this.$router.push({ name: "MarinLitEdit", params: { id: this.article.articleId } });
    },
    toggleArticlePreview() {
      this.isArticlePreviewVisible = !this.isArticlePreviewVisible;
    },
    async previewArticle() {
      // DS-2964 Preview from article list needs a separate API request to
      // fetch compounds ordered by MarinLit ID
      const result = await this.dbGetCompoundsByArticleId(this.article.articleId);
      if (result.Data && result.Data.numberOfResults > 0) {
        this.compounds = result.Data.results.map((c) => c.recordId);
      }
      this.toggleArticlePreview();
    },
    async updateArticleStatus(operation, key) {
      this.isSaving = true;
      this.clickedButton = key;
      this.$store.commit("addArticleStateCounter");
      let localCounter = 0;
      const result = await this[operation](this.article.articleId);
      // Success, now poll the search API until it reflects the change
      if (result.Data && result.Data.ok) {
        const sameStatus = false;
        this.statusUpdateCheckInterval = setInterval(async () => {
          const ready = await this.verifyArticleStatusUpdate(result.Data.articleId, sameStatus, this.getSearchQueryString);
          if (ready) {
            localCounter++;
            if (localCounter === 1) {
              this.$store.commit("subArticleStateCounter");
              if (this.$store.state.articleStateCounter === 0) {
                this.isSaving = false;
                this.isArticlePreviewVisible = false;
                this.$emit("disable");
                this.$emit("update", this.StatusMarinLit);
              }
            } else {
              this.isSaving = false;
              this.isArticlePreviewVisible = false;
            }
          }
        }, 1000);
      }
      // Failure, so reset the button and show an alert
      else {
        this.isSaving = false;
        this.clickedButton = "";
        this.$store.commit("subArticleStateCounter");
        // e.g. Status can not move from "draft" to "published"
        window.alert(result.ServiceStatus.Error.errorMessage);
      }
    },
  },
  components: {
    ArticleCitation,
    ArticleDoiLink,
    ArticlePreview,
    ModalWindow,
    WorkflowButtons,
  },
};
</script>
